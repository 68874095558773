import { useLogout } from "@/authentication/logout/util/useLogout"
import { useAuthUser } from "@/core/context/AuthUserContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import { DiscoFormLabel, DiscoText, DiscoTextButton } from "@disco-ui"
import { Grid } from "@material-ui/core"
import { FC } from "react"

interface Props {
  label: string
}

const CheckoutFormProfileAuthUserSection: FC<Props> = (props) => {
  const { label } = props

  const { authUser } = useAuthUser()
  const classes = useStyles()

  const logout = useLogout()

  if (!authUser) return null

  return (
    <>
      <DiscoFormLabel>{label}</DiscoFormLabel>
      <div
        className={classes.authUserContainer}
        data-testid={"CheckoutForm.auth-user-section"}
      >
        <Grid container spacing={2}>
          <Grid item>
            <ProfileAvatar
              testid={"CheckoutForm.ProfileAvatar"}
              userKey={authUser}
              size={48}
            />
          </Grid>
          <Grid item className={classes.details}>
            <DiscoText
              variant={"body-md-600"}
              testid={"CheckoutForm.member-fullname"}
              truncateText={1}
            >
              {authUser.fullName}
            </DiscoText>
            {authUser.email && (
              <DiscoText
                testid={"CheckoutForm.details"}
                variant={"body-sm"}
                color={"text.secondary"}
              >
                {authUser.email}
              </DiscoText>
            )}
          </Grid>
        </Grid>
        <DiscoTextButton
          onClick={logout}
          className={classes.logoutButton}
          testid={"CheckoutForm.logout"}
        >
          {"Logout"}
        </DiscoTextButton>
      </div>
    </>
  )
}
const useStyles = makeUseStyles((theme) => ({
  authUserContainer: {
    border: `1px solid ${theme.palette.groovy.grey[100]}`,
    borderRadius: theme.measure.borderRadius.medium,
    position: "relative",
    padding: "24px",
    marginBottom: "24px",
  },
  details: {
    width: "100%",
  },
  logoutButton: {
    position: "absolute",
    top: "9px",
    right: "12px",
    color: theme.palette.error.main,
  },
}))

export default CheckoutFormProfileAuthUserSection

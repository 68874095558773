import ROUTE_NAMES from "@/core/route/util/routeNames"
import {
  MemberGroupKind,
  MemberGroupRole,
  MemberGroupTagFragment$key,
} from "@/product/common/member-group/common/tag/__generated__/MemberGroupTagFragment.graphql"
import useGetGroupName from "@/product/common/member-group/common/utils/useGetGroupName"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoLink, DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoTag, { DiscoTagProps, DiscoTagSkeleton } from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"
import React, { ReactNode } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface MemberGroupTagProps extends Omit<DiscoTagProps, "name"> {
  testid?: string
  memberGroupKey?: MemberGroupTagFragment$key
  memberGroup?: {
    id?: GlobalID
    name: string
    kind: MemberGroupKind
    color: string
    role?: MemberGroupRole | null
    product?: { id: string; name: string } | null
    backgroundColor?: string
  }
  rightButton?: ReactNode
  maxWidth?: number
  showFullDetails?: boolean
  childGroups?: number | (MemberGroupTagFragment$key & { id: GlobalID })[]
  linkToMembers?: boolean
}

function MemberGroupTag({
  testid,
  memberGroupKey,
  memberGroup: propsMemberGroup,
  rightButton,
  maxWidth = 200,
  showFullDetails = false,
  childGroups,
  linkToMembers = false,
  backgroundColor,
  ...rest
}: MemberGroupTagProps) {
  const theme = useTheme()
  const getName = useGetGroupName()

  const mg = useFragment<MemberGroupTagFragment$key>(
    graphql`
      fragment MemberGroupTagFragment on MemberGroup {
        id
        name
        kind
        color
        role
        product {
          id
          name
        }
      }
    `,
    memberGroupKey || null
  )
  const memberGroup = mg || propsMemberGroup
  if (!memberGroup)
    throw new Error("One of memberGroupKey or memberGroup props must be provided")
  const classes = useStyles({
    backgroundColor:
      typeof backgroundColor === "function"
        ? backgroundColor(theme)
        : backgroundColor || memberGroup.color,
  })

  const tag = (
    <DiscoTag
      {...rest}
      testid={testid}
      name={getName(
        memberGroup.kind,
        memberGroup.name,
        showFullDetails,
        memberGroup.product || undefined,
        memberGroup.role
      )}
      backgroundColor={backgroundColor || memberGroup.color}
      tooltipBackgroundColor={theme.palette.groovy.neutral[100]}
      rightButton={renderRightButton()}
      maxWidth={maxWidth}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      leftIcon={
        (memberGroup.kind === "default" || memberGroup.kind === "role") && (
          <DiscoTooltip content={"System Group"} placement={"left"}>
            <div className={classes.icon}>
              <DiscoIcon icon={"group-share"} height={16} width={16} />
            </div>
          </DiscoTooltip>
        )
      }
    />
  )

  return linkToMembers && memberGroup.id ? (
    <DiscoLink
      to={{
        pathname: ROUTE_NAMES.COMMUNITY.MEMBERS.LIST,
        search: `g=${encodeURIComponent(memberGroup.id)}`,
      }}
    >
      {tag}
    </DiscoLink>
  ) : (
    tag
  )

  function renderRightButton() {
    if (rightButton) return rightButton
    const isCount = typeof childGroups === "number"
    const childGroupCount = isCount ? childGroups : childGroups?.length
    if (!childGroupCount) return null
    return (
      <DiscoTooltip
        content={
          isCount ? null : (
            <div className={classes.childrenTooltip}>
              {childGroups!.map((cg, i) => (
                <MemberGroupTag
                  key={cg.id}
                  testid={`${testid}.child.${i}`}
                  memberGroupKey={cg}
                />
              ))}
            </div>
          )
        }
        backgroundColor={theme.palette.groovy.neutral[100]}
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.arrow,
        }}
      >
        <div className={classes.rightIconContainer}>
          <DiscoIcon icon={"nesting"} width={16} height={16} marginLeft={-0.5} />
          <DiscoText variant={"body-xs-600"} className={classes.childCount}>
            {childGroupCount}
          </DiscoText>
        </div>
      </DiscoTooltip>
    )
  }
}

type StyleProps = {
  backgroundColor: string
}

const useStyles = makeUseStyles((theme) => ({
  tooltip: {
    maxWidth: "500px",
    border: theme.palette.constants.borderSmall,
    "& > span": {
      color: theme.palette.text.secondary,
    },
  },
  arrow: {
    display: "none",
  },
  icon: {
    display: "flex",
  },
  rightIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  childCount: ({ backgroundColor }: StyleProps) => ({
    color: theme.palette.getContrastText(backgroundColor),
  }),
  childrenTooltip: {
    display: "flex",
    gap: theme.spacing(1),
    flexWrap: "wrap",
  },
}))

export const MemberGroupTagSkeleton: React.FC = () => {
  return <DiscoTagSkeleton />
}

export default MemberGroupTag

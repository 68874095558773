import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { LocationState } from "@/core/route/util/routeUtils"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { runInAction } from "mobx"
import { useCallback, useEffect, useState } from "react"
import { generatePath, useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { graphql } from "relay-runtime"
import Relay from "../../../relay/relayUtils"
import { setSearchParams, useQueryParams } from "../../../utils/url/urlUtils"
import CheckoutStore from "./CheckoutStore"
import { useStartCheckoutMutation } from "./__generated__/useStartCheckoutMutation.graphql"
import {
  useStartCheckoutQuery,
  useStartCheckoutQuery$data,
} from "./__generated__/useStartCheckoutQuery.graphql"

export type CheckoutStoreQueryParams = {
  inviteToken?: string
}

export type CheckoutStoreReturnShape = {
  store: CheckoutStore
  product: useStartCheckoutQuery$data["product"]
}

/** Initialize a new CheckoutStore and get/create a checkout for the session */
export default function useStartCheckout(): CheckoutStoreReturnShape {
  const activeOrganization = useActiveOrganization()
  const organizationSlug = activeOrganization!.slug
  const registrationDrawer = useGlobalDrawer("registration")
  const { drawerRegistrationExperienceId, drawerRegistrationOccurrenceId } =
    registrationDrawer.params
  const history = useHistory()
  const match = useRouteMatch<{ productSlug?: string; occurrenceId?: string }>()
  const { productSlug: slug } = match.params
  const { membershipPlanSlug } = useQueryParams<{
    membershipPlanSlug: string
  }>()
  const location = useLocation<LocationState>()
  const ignorePriceOnPlan = location.state?.ignorePriceOnPlan
  const productSlug = membershipPlanSlug || slug

  if (
    !productSlug &&
    !drawerRegistrationExperienceId &&
    !drawerRegistrationOccurrenceId
  ) {
    throw new Error(
      "CheckoutPage expects a productSlug, productId, or occurrenceId route match parameter"
    )
  }

  const { authUser } = useAuthUser()

  const [store] = useState(() => new CheckoutStore({ authUser, ignorePriceOnPlan }))

  const [{ product }, refetchProduct] = Relay.useRefetchableQuery<useStartCheckoutQuery>(
    graphql`
      query useStartCheckoutQuery(
        $id: ID
        $slug: String
        $organizationSlug: String!
        $occurrenceId: ID
      ) {
        product(
          id: $id
          slug: $slug
          organizationSlug: $organizationSlug
          occurrenceId: $occurrenceId
        ) {
          id
          type
          name
          cover
          slug
          description
          ...ProductOnboardingInfoSection_Product
          ...CheckoutFormRegistrationErrorStep_Product
          myCheckout {
            ...CheckoutStore_Checkout @relay(mask: false)
          }
          landingPage {
            id
            metaTitle
            metaDescription
            metaImageUrl
          }
        }
      }
    `,
    {
      id: location.state?.joinMembershipPlanFirst
        ? null
        : drawerRegistrationExperienceId || null,
      slug: productSlug || null,
      occurrenceId: drawerRegistrationOccurrenceId || null,
      organizationSlug,
    },
    {
      refetchInBackground: true,
      // Get the latest myCheckout data from the backend whenever loading a checkout.
      fetchPolicy: "network-only",
    }
  )

  // Create a callback to create a new checkout
  const createCheckout = useCreateCheckoutMutation(store)

  // Update store with each query refetch
  useEffect(() => {
    store.response.set(product || null)
    if (product?.myCheckout) {
      // Load saved profile
      if (product.myCheckout.profile.userId) {
        store.profile.userId = product.myCheckout.profile.userId
      }
      if (product.myCheckout.profile.email) {
        store.profile.email = product.myCheckout.profile.email
        store.profile.confirmEmail = product.myCheckout.profile.email
      }
      if (product.myCheckout.profile.firstName) {
        store.profile.firstName = product.myCheckout.profile.firstName
      }
      if (product.myCheckout.profile.lastName) {
        store.profile.lastName = product.myCheckout.profile.lastName
      }
      // Populate form with saved answers
      if (product.myCheckout.application) {
        store.applicationAnswers.replace(product.myCheckout.application.answers.slice())
      }
      if (product.myCheckout.organizationForm) {
        store.organizationFormAnswers.replace(
          product.myCheckout.organizationForm.answers.slice()
        )
      }
    }
  }, [product, store])

  // Create new checkout on pageload
  useEffect(() => {
    createCheckout().then((didCreate) => {
      if (didCreate) {
        refetchProduct({
          id: registrationDrawer.params.drawerRegistrationExperienceId,
          slug: productSlug,
          organizationSlug,
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Read email, firstName, and lastName from query param and populate the form
  const queryParams = useQueryParams<{ email: string; fn: string; ln: string }>()
  useEffect(() => {
    runInAction(() => {
      if (queryParams.email && !store.profile.email) {
        store.profile.email = queryParams.email
        store.profile.confirmEmail = queryParams.email
      }
      if (queryParams.fn && !store.profile.firstName) {
        store.profile.firstName = queryParams.fn
      }
      if (queryParams.ln && !store.profile.lastName) {
        store.profile.lastName = queryParams.ln
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.email, queryParams.fn, queryParams.ln])

  useEffect(() => {
    // If in registration drawer, update the tab
    if (registrationDrawer.isOpen)
      return registrationDrawer.setParams({ registrationStep: store.currentStep })

    // If checking out via the registration path
    const registrationPath =
      product &&
      generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
        productSlug: product.slug,
      })
    const isOnRegistrationPath =
      registrationPath && location.pathname.startsWith(registrationPath)

    // If on register path, and drawer is not open, append the query params
    if (isOnRegistrationPath && !registrationDrawer.isOpen) {
      return history.replace({
        ...location,
        search: setSearchParams(location.search, { registrationStep: store.currentStep }),
      })
    }

    // If registering for membership plan, append the query params
    if (product?.type === "membership_plan" && !registrationDrawer.isOpen) {
      return history.replace({
        ...location,
        search: setSearchParams(location.search, { registrationStep: store.currentStep }),
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.currentStep])

  return { store, product }
}

/**
 * Builds a callback function that creates a new checkout.
 */
function useCreateCheckoutMutation(store: CheckoutStore): () => Promise<boolean> {
  const { inviteToken } = useQueryParams<CheckoutStoreQueryParams>()
  const match = useRouteMatch<{ productSlug?: string; occurrenceId?: string }>()
  const { productSlug: _productSlug, occurrenceId } = match.params

  const commitCreateMutation = Relay.useAsyncMutation<useStartCheckoutMutation>(
    graphql`
      mutation useStartCheckoutMutation(
        $productId: ID!
        $inviteToken: String
        $occurrenceId: ID
        $ignorePriceOnPlan: Boolean
      ) {
        createCheckout(
          productId: $productId
          inviteToken: $inviteToken
          occurrenceId: $occurrenceId
          ignorePriceOnPlan: $ignorePriceOnPlan
        ) {
          node {
            id
            ...CheckoutStore_Checkout
          }
          errors {
            field
            message
          }
        }
      }
    `
  )

  const createCheckout = useCallback(async () => {
    if (!store.product) return false
    try {
      const res = await commitCreateMutation({
        productId: store.product.id,
        inviteToken,
        occurrenceId,
        ignorePriceOnPlan: store.ignorePriceOnPlan,
      })
      if (res.createCheckout.errors) {
        store.startCheckoutError = res.createCheckout.errors[0]
        return false
      }
      return true
    } catch (err) {
      displayErrorToast(err)
      return false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commitCreateMutation, store.product?.id, inviteToken])

  return createCheckout
}
